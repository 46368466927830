import * as pageKitAppContext from '@financial-times/dotcom-ui-app-context';
import * as pageKitFlags from '@financial-times/dotcom-ui-flags';
import * as pageKitLayout from '@financial-times/dotcom-ui-layout';
import * as nTracking from '@financial-times/n-tracking';
import readyState from 'ready-state';
import { nMessagingClient } from '@financial-times/n-messaging-client/main-client';
import {
	initSourcepointCmp,
	updateFooterLinkCMP
} from '@financial-times/cmp-client';

readyState.dom.then(() => {
	const appContextClient = pageKitAppContext.init();
	const flagsClient = pageKitFlags.init();

	const appContext = appContextClient.getAll();

	if (flagsClient.get('oTracking')) {
		nTracking.init({ appContext });
	}

	pageKitLayout.init();

	// Track barrier page views.
	if (document.querySelector('[data-barrier]')) {
		nTracking.broadcast('oTracking.event', {
			category: 'barrier',
			action: 'view',
			opportunity: {
				type: 'barrier',
				subtype: 'ePaper'
			}
		});
	}

	// Track events passed in the response.
	const events = document.querySelectorAll('[data-epaper-tracking]');
	if (events.length > 0) {
		events.forEach((event) =>
			nTracking.broadcast('oTracking.event', JSON.parse(event.innerHTML))
		);
	}

	if (flagsClient.get('adsDisableInternalCMP')) {
		initSourcepointCmp();
		updateFooterLinkCMP();
	} else {
		nMessagingClient.init();
	}
});
